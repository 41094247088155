import React from 'react'
import Officerdashboard from '../../components/Officers/Officerdashboard/Officerdashboard'

const OfficerDashboard = () => {
  return (
    <>
      <Officerdashboard />
    </>
  )
}

export default OfficerDashboard
