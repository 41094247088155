import React from 'react'
import PropTypes from 'prop-types'
import { BarItemChoice, BarItemDiv } from './OfficerHorizontalBarStyle'

const OfficerHorizontalBar = ({ barItems }) => {
  OfficerHorizontalBar.propTypes = {
    barItems: PropTypes.any.isRequired
  }

  OfficerHorizontalBar.defaultProps = {
    barItems: {}
  }

  const selectColor = value => {
    if (value <= 20) {
      return '#EA0001'
    } else if (value <= 40) {
      return '#FA9924'
    } else if (value <= 60) {
      return '#FBBF07'
    } else if (value <= 80) {
      return '#30B36B'
    } else {
      return '#0CA651'
    }
  }

  return (
    <div className="w-100">
      {barItems.choices.map(item => (
        <BarItemDiv
          className="my-3"
          key={item.id}
          style={{ borderLeft: `4px solid ${selectColor((item.count / barItems.count) * 100)}` }}>
          <BarItemChoice className="mb-3">{item.choice}</BarItemChoice>
          <div className="progress" style={{ height: '10px' }}>
            <div
              className={'progress-bar'}
              role="progressbar"
              style={{
                width: `${(item.count / barItems.count) * 100}%`,
                backgroundColor: `${selectColor((item.count / barItems.count) * 100)}`,
                borderRadius: '0.25rem'
              }}
            />
          </div>
        </BarItemDiv>
      ))}
    </div>
  )
}
export default OfficerHorizontalBar
