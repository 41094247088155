import { makeStyles } from '@material-ui/core'

export const useSentitmentStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    margin: 'auto',
    maxWidth: 650,
    padding: 12,
    '& button': {
      width: '100% !important'
    }
  },
  selectedFiles: {
    marginBottom: 10,
    border: '1.5px solid #3D8373',
    backgroundColor: '#FFFFFF',
    padding: '10px 24px'
  },
  container: {
    padding: 0,
    marginBottom: 10
  },
  dropZoneInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 30
  },
  deleteFile: {
    cursor: 'pointer'
  },
  alertSpacing: {
    marginBottom: 10
  },
  uploadedText: {
    marginBottom: '5px !important'
  },
  instructions: {
    marginTop: 30,
    backgroundColor: '#E9ECEF',
    padding: '16px 32px',
    '& ol': {
      paddingLeft: 25
    }
  },
  copyWrapper: {
    marginTop: 20
  },
  copyPrompt: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 24px',
    backgroundColor: '#E9ECEF',
    borderRadius: '0px 0px 8px 8px',
    '& svg': {
      fontSize: 24
    },
    '& p': {
      marginLeft: '10px !important'
    }
  },
  analyzerPaper: {
    padding: 24,
    borderRadius: '8px 8px 0px 0px'
  },
  analyzerChatPaper: {
    marginTop: 20,
    display: 'flex',
    padding: 24,
    borderRadius: '8px'
  },
  analyzerChatPaperLoader: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 24,
    width: '100%',
    height: '100%',
    borderRadius: '8px'
  },
  userChat: {
    // backgroundColor: '#bef3fa',
    marginLeft: 50,
    justifyContent: 'start'
  },
  assistantChat: {
    backgroundColor: '#e0e0e0',
    marginRight: 50,
    justifyContent: 'start'
  },
  avatarBox: {
    width: 50,
    height: 50,
    marginRight: 20
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 50
  },
  chatWrapper: {
    minHeight: 450,
    marginTop: 20
  },
  chatInput: {
    '& span': {
      color: '#AAAAAA'
    }
  },
  analyzeInput: {
    backgroundColor: '#fff'
  },
  radioButtons: {
    '& span': { visibility: 'visible !important', width: '10% !important' }
  },
  subText: {
    color: '#BDBDBD'
  }
}))
