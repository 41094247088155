import { SET_COMMUNITY_SURVEY } from '../actionType'

const initState = {
  community: {}
}

const communityReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_COMMUNITY_SURVEY:
      return {
        ...state,
        community: action.payload
      }

    default:
      return state
  }
}

export default communityReducer
