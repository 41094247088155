/* eslint-disable */
import React, { useMemo, useState } from 'react'
import Alert from '@material-ui/lab/Alert'
import { Box, Container, Paper, Typography } from '@material-ui/core'
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
import { analizeSentimentsFileUpload } from '../../utils/apiUrls'
import { OfficerButton } from '../Common'
import { useDropzone } from 'react-dropzone'
import { DeleteFilledSvg, UploadSvg } from '../../utils/svgs'
import { useSentitmentStyles } from './SentimentAnalysis.style'
import Analyzer from './Analyzer/Analyzer'
import { analizeFileContent } from '../../utils/api'
import CircularProgress from '@material-ui/core/CircularProgress'
// import { Col, Row } from 'react-bootstrap';
// import { grey } from '@material-ui/core/colors';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: '#A6A8B0',
  borderStyle: 'dashed',
  backgroundColor: '#F0F2FF',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const SentimentAnalysis = ({ props }) => {
  const maxSelection = 3
  const classes = useSentitmentStyles()
  const [selectedFiles, setSelectedFiles] = useState([])
  const [uploadFileData, setUploadFileData] = useState()
  const [analyze, setAnalyze] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [noFilesError, setNoFilesError] = useState(false)
  // const [selectType, setSelectType] = useState(2)

  const onDrop = acceptedFiles => {
    // debugger
    // Filter out duplicates
    const uniqueFiles = acceptedFiles.filter(
      file => !selectedFiles.some(selectedFile => selectedFile.name === file.name)
    )

    // Ensure the total number of selected files doesn't exceed the maximum
    const remainingSpace = maxSelection - selectedFiles.length
    const filesToAdd = uniqueFiles.slice(0, remainingSpace)

    // Concatenate the new files with the existing ones
    setSelectedFiles([...selectedFiles, ...filesToAdd])
  }

  const { open, fileRejections, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': [],
      'video/mp4': [],
      'application/pdf': [],
      'image/vnd.adobe.photoshop': [], // PSD
      'application/postscript': [], // AI
      'application/msword': [], // WORD
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': [] // PPT
    },
    multiple: true,
    maxFiles: 3,
    maxSize: 5000000,
    onDrop,
    disabled: selectedFiles.length > 2,
    onDropRejected: rejected => console.log({ rejected })
  })

  const removeFile = indexToRemove => {
    const updatedFiles = selectedFiles.filter((_, index) => index !== indexToRemove)
    setSelectedFiles(updatedFiles)
  }

  const acceptedFileItems = selectedFiles.map((file, index) => (
    <Alert
      icon={false}
      key={file.path}
      severity="info"
      className={classes.selectedFiles}
      action={
        <span className={classes.deleteFile} onClick={() => removeFile(index)}>
          <DeleteFilledSvg color="#FFE0E1" />
        </span>
      }>
      {file.path}
    </Alert>
  ))

  const fileRejectionItems = fileRejections
    .filter(({ file }) => !selectedFiles.some(selectedFile => selectedFile.name === file.name))
    .map(({ file, errors }) => (
      <Alert key={file.path} severity="error" className={classes.alertSpacing}>
        Rejected: {file.path}
        <ul>
          {errors.map(e => (
            <li key={e.code}>{e.code === 'file-too-large' ? 'File is larger than 5MB' : e.message}</li>
          ))}
        </ul>
      </Alert>
    ))

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  const uploadfile = async () => {
    if (selectedFiles.length > 0) {
      setNoFilesError(false)
      setIsLoading(true)
      var formdata = new FormData()
      selectedFiles.length &&
        selectedFiles.map(item => {
          formdata.append('files', item)
        })
      // formdata.append("code_type", selectType)
      const res = await analizeFileContent(`${analizeSentimentsFileUpload}`, formdata)
      if (res.data.data.status) {
        setIsLoading(false)
        props.notify('Something Went Wrong', 'error')
      } else {
        setUploadFileData(res.data.data)
        setAnalyze(true)
        setIsLoading(false)
      }
    } else {
      setNoFilesError(true)
    }
  }

  return (
    <>
      {analyze ? (
        <Analyzer uploadedFiles={uploadFileData} />
      ) : (
        <Box className={classes.root}>
          <Paper className={classes.paper}>
            <Box>
              <Container className={classes.container}>
                <div {...getRootProps({ className: 'dropzone', style })}>
                  <input {...getInputProps()} />
                  <Box className={classes.dropZoneInput}>
                    <Box>
                      <UploadSvg color="#003399" />
                    </Box>
                    <Box>
                      <p>Drag & drop files or Browse</p>
                      <em>Supported formats: JPEG, PNG, GIF, MP4, PDF, PSD, AI, WORD, PPT</em>
                    </Box>
                  </Box>
                </div>
              </Container>
              <Typography variant="body2" className={classes.uploadedText}>
                Uploading - {selectedFiles.length}/{maxSelection} files
              </Typography>
              <OfficerButton
                click={open}
                buttonName="Upload Files"
                color="officer"
                variant="medium"
                disabled={selectedFiles.length > 2}
              />
            </Box>
            <Box>
              <Box>
                {/* <Typography variant="overline">Uploaded</Typography> */}
                <Box className="mt-2">
                  {acceptedFileItems && acceptedFileItems.length === 0 && fileRejectionItems.length === 0 && (
                    <Alert className={classes.alertSpacing} icon={false} severity="error">
                      {noFilesError ? 'Please Select a File' : 'No file selected'}
                    </Alert>
                  )}

                  {acceptedFileItems}
                  {fileRejectionItems}
                </Box>
              </Box>
              {/* <Box>
                <RadioGroup value={selectType} className={classes.radioButtonGroup} >
                  <Row className='mt-2'>
                    <Col xs={5}>
                      <Radio
                        className={classes.radioButtons}
                        value={1}
                        name="text_Based"
                        onClick={() => setSelectType(1)}
                      />
                      <label htmlFor='text_Based'>Text Based Result</label>
                    </Col>
                    <Col xs={7}>
                      <Radio
                        className={classes.radioButtons}
                        value={2}
                        name="visual_based"
                        onClick={() => setSelectType(2)}
                      />
                      <label htmlFor="visual_based">Visual Based Result <span className={classes.subText}>(Takes Upto 5 min)</span></label>
                    </Col>
                  </Row>
                </RadioGroup>
              </Box> */}
              <OfficerButton
                disabled={acceptedFileItems.length === 0 ? false : isLoading ? true : false}
                buttonName="Continue"
                color="officer"
                variant="medium"
                click={() => uploadfile()}
              />
            </Box>
            {isLoading ? (
              <Paper className={classes.analyzerChatPaperLoader}>
                <CircularProgress />
              </Paper>
            ) : null}
          </Paper>
          <Paper className={classes.instructions}>
            <Typography variant="h5">Instructions</Typography>
            <ol>
              <li>Upload your employee or community feedback survey results.</li>
              <li>
                On the next page, give the Ai a prompt. This tells our system to analyze the sentiment of your uploaded
                survey responses.
              </li>
              <li>
                The system will process the responses and provide an overall sentiment analysis categorizing the
                feedback as positive, negative or neutral.
              </li>
              <li>
                Additionally, the analysis may highlight key themes that emerge from the responses. This can help
                provide further insight into what is driving certain sentiments.
              </li>
              <li>
                If you need to tweak or refine the analysis in any way, feel free to edit the prompt as needed before
                submitting your survey responses. This allows you to guide the system if you are looking for any
                specific aspects to be covered.
              </li>
            </ol>
          </Paper>
        </Box>
      )}
    </>
  )
}

export default SentimentAnalysis
