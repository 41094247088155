/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { Label } from './OfficerSelectStyle'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import PropTypes from 'prop-types'
import './OfficerSelect.css'

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 0,
    marginBottom: '0 !important',
    minWidth: 120
  },
  selectEmpty: {
    marginTop: 0,
    height: 56,
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  }
}))

const OfficerSelect = ({ id, label, value, placeholder, onChange, langArray, hintTxt, hintUrl, disabled }) => {
  OfficerSelect.defaultProps = {
    id,
    label: '',
    placeholder: '',
    value: '',
    langArray: null,
    hintTxt: '',
    hintUrl: 'https://platform.openai.com/docs',
    disabled: false
  }

  OfficerSelect.propTypes = {
    id: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    langArray: PropTypes.any,
    hintTxt: PropTypes.string,
    hintUrl: PropTypes.string,
    disabled: PropTypes.bool
  }

  const classes = useStyles()

  return (
    <div className="">
      <Label className="py-2 pl-2 labelStyle">{label}</Label>
      <br />
      {hintTxt ? (
        <p className="pl-2 hintStyle">
          HINT: {hintTxt}{' '}
          <a href={hintUrl} rel="noopener noreferrer" target="_blank">
            click here!
          </a>
        </p>
      ) : (
        ''
      )}
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={value}
          onChange={onChange}
          displayEmpty
          disabled={disabled}
          className={classes.selectEmpty}>
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
          {langArray &&
            langArray.map(el => (
              <MenuItem key={el.name} value={el.name}>
                {el.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  )
}
export default OfficerSelect
