/* eslint-disable */
import { Box, Button, Divider, InputAdornment, Paper, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { CopySvg } from '../../../utils/svgs'
import { useSentitmentStyles } from '../SentimentAnalysis.style'
import { analizeSentiments, analizeSentimentsMessage } from '../../../utils/apiUrls'
import { OfficerButton } from '../../Common'
// import { createFormDataObject } from '../../../utils/helpers'
import { analizeFileContent } from '../../../utils/api'
import userLogo from '../../../assets/default_profile_pic.png'
import assistantLogo from '../../../assets/logo_grey.png'
// import CircularProgress from '@material-ui/core/CircularProgress';
import TypingLoader from '../../Common/TypingLoader/TypingLoader'
import PropTypes from 'prop-types'

const Analyzer = ({ props, uploadedFiles }) => {
  // Add prop types validation
  Analyzer.propTypes = {
    props: PropTypes.object.isRequired,
    uploadedFiles: PropTypes.shape({
      file_ids: PropTypes.array.isRequired,
      assistent_id: PropTypes.string.isRequired,
      thread_id: PropTypes.string.isRequired
    }).isRequired,
    notify: PropTypes.func.isRequired
  }
  const classes = useSentitmentStyles()
  const promptRef = useRef()
  const [copied, setCopied] = useState(false)
  const [textMessage, setTextMessage] = useState('')
  const [userMessage, setUserMessage] = useState([])
  const [typing, setTyping] = useState(false)
  const [messLength, setMessLength] = useState(0)
  const autoScroll = useRef(null)
  const [currentMessage, setCurrentMessage] = useState({
    role: '',
    content: ''
  })
  const [failedFromAi, setfailedFromAi] = useState(false)

  useEffect(() => {
    if (userMessage.length) {
      autoScroll.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
    }
  }, [userMessage])

  const copyContent = () => {
    const textToCopy = promptRef.current.innerText

    // Use the Clipboard API to copy text to the clipboard
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 1000)
      })
      .catch(err => {
        // eslint-disable-line no-console
        console.log(err)
        setCopied(false)
      })
  }

  const handleAnalyze = async () => {
    if (textMessage !== '') {
      setfailedFromAi(false)
      setTyping(true)
      const msgData = {
        prompt: textMessage,
        file_ids: uploadedFiles.file_ids,
        assistent_id: uploadedFiles.assistent_id,
        thread_id: uploadedFiles.thread_id
      }
      const res = await analizeFileContent(`/${analizeSentiments}`, msgData)
      if (res.data.data.status) {
        setTyping(false)
        props.notify('Something Went Wrong', 'error')
      } else {
        // setUserMessage([...userMessage, ...res.data.data])
        const createMessage = res.data.data.thread_id
        let runStatus = 'not_started'
        const interval = setInterval(async function () {
          const getMessagesRes = await analizeFileContent(`/${analizeSentimentsMessage}`, { thread_id: createMessage })
          const messages = getMessagesRes.data.data.message
          runStatus = getMessagesRes.data.data.runStatus
          if (runStatus === 'completed') {
            setTextMessage('')
            setUserMessage(messages)
            setTyping(false)
            clearInterval(interval)
          } else if (runStatus === 'failed') {
            setfailedFromAi(true)
            setTyping(false)
            clearInterval(interval)
          } else if (runStatus === 'in_progress') {
            setTextMessage('')
            setUserMessage(messages)
          }
        }, 3000)
      }
    }
  }

  const typeStateEff = lastMessage => {
    setMessLength(userMessage.length)
    let currentIndex = 0
    const intervalId = setInterval(() => {
      setCurrentMessage(prevMessage => ({
        ...prevMessage,
        content: lastMessage.content.slice(0, currentIndex)
      }))
      currentIndex++

      if (currentIndex > lastMessage.content.length) {
        clearInterval(intervalId)
      }
    }, 50) // Adjust the interval duration as needed

    return () => {
      clearInterval(intervalId)
    }
  }

  useEffect(() => {
    if (userMessage.length > messLength) {
      const lastMessage = userMessage[userMessage.length - 1]
      if (lastMessage.content !== '') {
        typeStateEff(lastMessage)
      } else {
        // Reset currentMessage if the last message has no content
        setCurrentMessage(prevMessage => ({
          ...prevMessage,
          content: ''
        }))
      }
    }
    // eslint-disable-next-line
  }, [userMessage])

  return (
    <>
      <div ref={autoScroll}>
        <Typography
          variant="body2"
          style={{
            fontSize: 14,
            backgroundColor: '#E9ECEF',
            borderRadius: 10,

            padding: 10,
            fontWeight: 'bold'
          }}>
          To analyze your survey results, you can use our recommended prompt listed below or compose your own custom
          prompt.
        </Typography>
        <Box className={classes.copyWrapper}>
          <Paper className={classes.analyzerPaper}>
            <Typography variant="body2" ref={promptRef}>
              Conduct a comprehensive analysis of the survey data provided, extracting relevant insights and patterns.
              Provide a text-based summary of key findings based on the survey results, covering various question
              formats such as multiple-choice, rating, open text, and any other response types present.
            </Typography>
          </Paper>
          <Box className={classes.copyPrompt}>
            <Button onClick={copyContent}>
              <CopySvg color="#000000" />
              <Typography variant="body2">{`${copied ? 'Copied' : 'Copy Prompt'}`}</Typography>
            </Button>
          </Box>
        </Box>
        <Box>
          <Box className={classes.chatWrapper}>
            {!failedFromAi ? (
              <>
                {userMessage && userMessage.length
                  ? userMessage.map((item, index) => {
                      return (
                        <React.Fragment key={`${index} + ${item.role}`}>
                          {item.content === '' ? null : (
                            <Paper
                              className={`${classes.analyzerChatPaper} ${
                                item.role === 'user' ? classes.userChat : classes.assistantChat
                              }`}>
                              <div className={classes.avatarBox}>
                                <img src={item.role === 'user' ? userLogo : assistantLogo} className={classes.avatar} />
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: index !== userMessage.length - 1 ? item.content : currentMessage.content
                                }}
                              />
                            </Paper>
                          )}
                        </React.Fragment>
                      )
                    })
                  : null}
                {typing ? (
                  <Paper className={classes.analyzerChatPaperLoader}>
                    <div className="mt-3">
                      <TypingLoader />
                    </div>
                  </Paper>
                ) : null}
              </>
            ) : (
              <Paper className={classes.analyzerChatPaperLoader}>
                <div className="mt-3">OpenAI Cross Query Limits</div>
              </Paper>
            )}
          </Box>
          <Divider style={{ marginBottom: 15 }} />
          <Box className={classes.chatInput}>
            <TextField
              disabled={typing ? true : false}
              className={classes.analyzeInput}
              type="text "
              variant="outlined"
              placeholder="Enter your prompt..."
              value={textMessage}
              onChange={event => setTextMessage(event.target.value)}
              InputProps={{
                inputProps: {
                  maxLength: 400
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <OfficerButton
                      disabled={typing ? true : false}
                      buttonName="Analyze"
                      color="officer"
                      variant="medium"
                      click={handleAnalyze}
                    />
                  </InputAdornment>
                )
              }}
            />
            <Typography variant="caption">{textMessage.length}/400 Characters</Typography>
          </Box>
          <Divider style={{ marginTop: 15 }} />
        </Box>
      </div>
    </>
  )
}

export default Analyzer
