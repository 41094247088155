import styled from 'styled-components'

export const BarItemDiv = styled.div`
  padding: 20px;
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const BarItemChoice = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #323c47;
  text-align: justify;
`
