import styled from 'styled-components'
export const SupportHeading = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 56px;
`

export const SupportHelpDocs = styled.a`
  font-family: 'Poppins';
  background-color: #003399;
  color: white;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0;
  padding: 8px 10px;
  :hover {
    opacity: 0.9;
    color: #ffffff !important;
    text-decoration: none;
    cursor: pointer;
  }
`
