import React from 'react'
import './style.css'

const TypingLoader = () => {
  return (
    <div className="bouncing-loader">
      <div />
      <div />
      <div />
    </div>
  )
}

export default TypingLoader
