/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState } from 'react'
import OfficerButton from '../Common/OfficerButton/OfficerButton'
import { CircularProgress } from '@material-ui/core'
import { callChatGpt } from '../../utils/api'
import { chatGptTurboUrl, promptExample } from '../../utils/apiUrls'
import { OfficerInputField } from '../Common'
import logo from '../../assets/logo_officer.png'
import PropTypes from 'prop-types'
import './PressRelease.css'
import parse from 'html-react-parser'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const PressRelease = ({ notify }) => {
  PressRelease.propTypes = {
    notify: PropTypes.func.isRequired
  }

  const [announcement, setAnnouncement] = useState('')
  const [toneOfVoice, setToneOfVoice] = useState('')

  const copyResponse = async () => {
    notify('There is no any response', 'warning', 1000)
  }

  const [turboResult, setTurboResult] = useState('')
  const [chatSpinner, setChatSpinner] = useState(false)
  const generateChatGPT = async () => {
    if (announcement.length > 0) {
      setChatSpinner(true)
      var keywords = announcement.concat(' ', toneOfVoice)
      const res = await callChatGpt(chatGptTurboUrl, keywords)
      if (!res.isError) {
        setTurboResult(res.data.data.choices[0].message.content)
        setChatSpinner(false)
      } else {
        setTurboResult(res.error.error.message)
        setChatSpinner(false)
      }
    } else {
      notify('Please add your survey!', 'warning')
    }
  }
  return (
    <>
      <div className="d-flex flex-wrap flex-lg-nowrap justify-content-around">
        <div className="press-survey">
          <div className="generate-survey-main">
            <p className="generate-survey-title">Press Release Generator</p>
            <p className="generate-survey-hint">Insert any relevant information and instructions and click generate.</p>
            <p className="generate-survey-hint-url">
              <a href={promptExample} rel="noopener noreferrer" target="_blank">
                Click here for prompt example!
              </a>
            </p>
            <div className="announcement-overview">
              <OfficerInputField
                placeholder="Include details about the incident or issue, such as the date, time, location, and any relevant background information."
                id="announcement"
                name="announcement"
                maxLength={1000}
                multiline
                rows={3}
                characterCount
                value={announcement}
                className="type-field"
                onChange={event => {
                  setAnnouncement(event.target.value)
                }}
                label="Announcement Overview"
              />
            </div>
            <div className="tone-of-voice">
              <OfficerInputField
                placeholder="E.g, Captivating"
                id="toneofVoice"
                name="toneofVoice"
                value={toneOfVoice}
                className="audience-field"
                onChange={event => setToneOfVoice(event.target.value)}
                label="Tone of Voice (Optional)"
              />
            </div>
          </div>

          <div className="mb-4 mt-4">
            <OfficerButton
              buttonName="Generate"
              color="officer"
              align="right"
              variant="extraLarge"
              click={generateChatGPT}
            />
          </div>
        </div>
        <div className="press-results">
          <div className="results-title">Results</div>
          <div className="result-box">
            <div>{chatSpinner ? <CircularProgress /> : ''}</div>
            <div>{parse(turboResult.trim().toString().replace(/\n/g, '<br />'))}</div>
          </div>
          <div className="pt-4 d-flex flex-wrap justify-content-between">
            {turboResult ? (
              <CopyToClipboard text={parse(turboResult)} onCopy={() => notify('Copied', 'success', 1000)}>
                <div className="custom-copy-btn">Copy Response</div>
              </CopyToClipboard>
            ) : (
              <OfficerButton
                buttonName="Copy Response"
                color="officer"
                align="left"
                variant="extraLarge"
                disabled={turboResult.length === 0 ? true : false}
                click={copyResponse}
              />
            )}
            <OfficerButton
              buttonName="Regenerate Response"
              color="officer"
              align="right"
              variant="extraLarge"
              disabled={turboResult.length === 0 ? true : false}
              click={generateChatGPT}
            />
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className="mt-4">Powered By</p>
        <img src={logo} alt="officerLogo" width="250x" height="100px" className="logoStyle" />
      </div>
    </>
  )
}
export default PressRelease
