/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import FormLabel from '@material-ui/core/FormLabel'
import { useParams, useHistory } from 'react-router-dom'
import { getAPI, putAPIFormDataWrapper } from '../../../utils/api'
import { StatesArray } from '../../../utils/Constants'
import OfficerButton from '../../Common/OfficerButton'
import DefaultProfilePic from '../../../assets/default_profile_pic.png'
import { emailFormatVerification } from '../../../utils/helpers'
import { createFormDataObject, allnumeric } from '../../../utils/helpers'
import { getUserId, getIsSupervisor, setUserRoles, setProfilePic } from '../../../utils/localStorage'
import OfficerLoader from '../../Common/OfficerLoader/OfficerLoader'
import { officersUrl, districtUrl, officersListUrl } from '../../../utils/apiUrls'
import { OfficerAutocomplete, OfficerCard, OfficerImageUpload, OfficerInputField } from '../../Common'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { setUser } from '../../../redux/actions'
import './UpdateOfficer.css'

const UpdateOfficer = props => {
  const dispatch = useDispatch()

  UpdateOfficer.propTypes = {
    notify: PropTypes.func.isRequired
  }

  const [officerData, setOfficerData] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    badge_number: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    district: '',
    profile_pic: DefaultProfilePic,
    is_profile_pic_change: false,
    is_supervisor: false,
    is_community: false,
    is_employee: false,
    user_roles: '',
    showRoles: true,
    notifications_districts: ''
  })
  const [showSpinner, setShowSpinner] = useState(false)
  const [fetchingUpdate, setFetchingUpdate] = useState(false)
  const [districts, setDistricts] = useState([])
  const [notificationDist, setNotificationDist] = useState([{ id: 'all', name: 'All District' }])
  const [selectedNotification, setSelectedNotification] = useState({ id: '', name: '' })
  const [selectedDirtcict, setSelectedDistrict] = useState({ id: '', name: '' })
  const history = useHistory()
  const [imgSrc, setImgSrc] = useState()
  const [loginUserSuper, setLoginUserSuper] = useState(false)
  let { updateIdOfficer } = useParams()
  const fetchOfficerDetails = async () => {
    const localOfficer = await getUserId()
    if (updateIdOfficer === undefined) {
      updateIdOfficer = localOfficer
      const supervisor = await getIsSupervisor()
      setOfficerData({ ...officerData, is_supervisor: supervisor })
    }
    setFetchingUpdate(true)
    const response = await getAPI(`${officersUrl}${updateIdOfficer}`)
    const data = response.data.data.data
    if (!response.isError) {
      setFetchingUpdate(false)
      setOfficerData({
        email: data.user.email,
        first_name: data.first_name,
        last_name: data.last_name,
        badge_number: data.badge_number,
        address: data.address,
        phone: data.phone,
        city: data.city,
        state: data.state,
        zip_code: data.zip_code,
        district_name: data.district ? data.district.name : '',
        district: data.district ? data.district.id : null,
        profile_pic: data.user.profile_pic,
        is_supervisor: data.is_supervisor || data.user_roles === 'supervisor',
        is_community: data.user_roles === 'community' ? true : false,
        is_employee: data.user_roles === 'employee' ? true : false,
        user_roles:
          data.user_roles === '' || data.user_roles === null
            ? data.is_supervisor
              ? 'supervisor'
              : ''
            : data.user_roles,
        showRoles: data.is_supervisor ? true : false,
        notifications_districts: data.notifications_districts
      })
      setSelectedDistrict(data.district)
      setSelectedNotification({
        id: data.notifications_districts === 'null' ? 'all' : data.notifications_districts,
        name:
          data.notifications_districts === 'all'
            ? 'All District'
            : data.notifications_districts === 'null'
            ? 'All District'
            : data.notifications_districts
      })
    }
  }

  const fetchDistrictsAPI = async () => {
    const response = await getAPI(districtUrl)
    setDistricts(response.data.data.data)
    setNotificationDist(notificationDist.concat(response.data.data.data))
  }

  useEffect(() => {
    fetchOfficerDetails()
    fetchDistrictsAPI()
    isSupervisor()
  }, [])

  const isSupervisor = async () => {
    const loginIsSupervisor = await getIsSupervisor()
    setLoginUserSuper(loginIsSupervisor)
  }

  const onRequiredSwitchChange = e => {
    if (e.target.value === 'supervisor') {
      if (officerData.is_supervisor === false) {
        setOfficerData({
          ...officerData,
          is_supervisor: true,
          is_community: false,
          is_employee: false,
          showRoles: true,
          user_roles: 'supervisor'
        })
      } else {
        setOfficerData({
          ...officerData,
          is_supervisor: false,
          is_community: false,
          is_employee: false,
          showRoles: false,
          user_roles: ''
        })
      }
    } else if (e.target.value === 'community') {
      if (officerData.is_community === false) {
        setOfficerData({
          ...officerData,
          showRoles: false,
          is_supervisor: false,
          is_community: true,
          is_employee: false,
          user_roles: 'community',
          notifications_districts: null
        })
      } else {
        setOfficerData({
          ...officerData,
          showRoles: false,
          is_supervisor: false,
          is_community: false,
          is_employee: false,
          user_roles: '',
          notifications_districts: null
        })
      }
    } else if (e.target.value === 'employee') {
      if (officerData.is_employee === false) {
        setOfficerData({
          ...officerData,
          showRoles: false,
          is_supervisor: false,
          is_community: false,
          is_employee: true,
          user_roles: 'employee',
          notifications_districts: null
        })
      } else {
        setOfficerData({
          ...officerData,
          showRoles: false,
          is_supervisor: false,
          is_community: false,
          is_employee: false,
          user_Role: '',
          notifications_districts: null
        })
      }
    }
  }
  const updateOfficer = async () => {
    setShowSpinner(true)
    let is_profile = false
    const user = await getUserId()
    if (updateIdOfficer === undefined) {
      is_profile = true
      updateIdOfficer = user
    }
    const formData = createFormDataObject(officerData)
    const res = await putAPIFormDataWrapper(`/${officersListUrl}${updateIdOfficer}/`, formData)
    if (!res.isError) {
      setShowSpinner(false)
      if (is_profile) {
        await setProfilePic(res.data.data.data.user.profile_pic)
        dispatch(setUser(res.data.data.data))
        await setUserRoles(res.data.data.data.user_roles)

        props.notify('Profile Updated', 'success')
        if (res.data.data.data.user_roles === 'community') {
          history.push('/community-feedback')
        } else if (res.data.data.data.user_roles === 'employee') {
          history.push('/employee-feedback')
        } else {
          history.push('/dashboard')
        }
      } else {
        props.notify(res.data.data.message, 'success')
        history.push('/officers')
      }
    } else {
      props.notify(res.error.message, 'error')
      setShowSpinner(false)
    }
  }
  const handleOfficerDataChange = e => {
    const { value, name, files } = e.target
    setOfficerData({
      ...officerData,
      [name]: name === 'profile_pic' ? files[0] : name === 'email' ? value.toLowerCase().replace(/\s/g, '') : value,
      is_profile_pic_change: name === 'profile_pic' ? true : false
    })
    if (name === 'profile_pic') {
      var file = files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function () {
        setImgSrc([reader.result])
      }
    }
  }

  const selectedNotificationName = notificationDist.find(
    dist => dist.id.toString() === selectedNotification.id.toString()
  )

  return (
    <>
      <div>
        <OfficerLoader isFetching={fetchingUpdate}>
          <Grid container justify="center" alignItems="flex-start">
            <Grid item sm={12} md={4}>
              <OfficerCard>
                <OfficerImageUpload
                  src={imgSrc ? imgSrc : DefaultProfilePic}
                  alt="Update Officer Img : "
                  type="file"
                  placeholder="No file choosen"
                  name="profile_pic"
                  onChange={handleOfficerDataChange}
                  imgSrcAlt="Select Image"
                />
              </OfficerCard>
            </Grid>
          </Grid>
          <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
            <Box borderRadius={18} p={4} display="flex" flexDirection="column" width="100%">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <OfficerInputField
                    placeholder="Enter First Name"
                    id="Enter First Name"
                    required
                    type="text"
                    label="First Name *"
                    name="first_name"
                    className="update-officer-inputfield"
                    value={officerData.first_name}
                    onChange={handleOfficerDataChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <OfficerInputField
                    placeholder="Enter Last Name"
                    required
                    id="Enter Last Name"
                    type="text"
                    label="Last Name *"
                    name="last_name"
                    className="update-officer-inputfield"
                    value={officerData.last_name}
                    onChange={handleOfficerDataChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <OfficerInputField
                    placeholder="Enter Badge Number"
                    required
                    id="Enter Badge Number"
                    label="Badge Number *"
                    name="badge_number"
                    className="update-officer-inputfield"
                    value={officerData.badge_number}
                    onChange={handleOfficerDataChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <OfficerInputField
                    placeholder="Enter Email"
                    id="Enter Email"
                    required
                    type="text"
                    label="Work Email Address *"
                    name="email"
                    className="update-officer-inputfield"
                    value={officerData.email}
                    onChange={handleOfficerDataChange}
                    error={officerData.email.length > 0 && emailFormatVerification(officerData.email)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <OfficerInputField
                    placeholder="Enter Address"
                    id="Enter Address"
                    type="text"
                    label="Work Address *"
                    name="address"
                    className="update-officer-inputfield"
                    value={officerData.address}
                    onChange={handleOfficerDataChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <OfficerInputField
                    placeholder="Enter City"
                    id="Enter City"
                    type="text"
                    label="City *"
                    name="city"
                    className="update-officer-inputfield"
                    value={officerData.city}
                    onChange={handleOfficerDataChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <OfficerAutocomplete
                    id="combo-box-update-officer-state"
                    options={StatesArray}
                    label="State *"
                    idTextField="State"
                    placeholder="Enter State"
                    value={officerData.state}
                    className="update-officer-autocomplete"
                    onChange={(event, newValue) => {
                      if (newValue !== undefined) {
                        if (newValue)
                          setOfficerData({
                            ...officerData,
                            state: newValue
                          })
                        else
                          setOfficerData({
                            ...officerData,
                            state: ''
                          })
                      }
                    }}
                    getOptionLabel={option => (option ? option : '')}
                    getOptionSelected={(option, value) => {
                      if (value === '') {
                        return true
                      } else if (value === option) {
                        return true
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <OfficerInputField
                    placeholder="Enter Zip Code"
                    id="Enter Zip Code"
                    type="number"
                    label="Zip Code * (Sholuld be 5 digits long)"
                    name="zip_code"
                    className="update-officer-inputfield"
                    value={officerData.zip_code}
                    onChange={handleOfficerDataChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <OfficerInputField
                    placeholder="Enter Phone Number"
                    id="Enter Phone Number"
                    type="text"
                    label="Phone Number *"
                    name="phone"
                    className="update-officer-inputfield"
                    value={officerData.phone}
                    onChange={handleOfficerDataChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <OfficerAutocomplete
                    id="combo-box-update-district"
                    options={districts}
                    idTextField="District"
                    label="Assignment *"
                    placeholder="Enter Assignment"
                    className="update-officer-autocomplete"
                    value={selectedDirtcict}
                    onChange={(event, newValue) => {
                      if (newValue !== undefined) {
                        if (newValue) {
                          setOfficerData({
                            ...officerData,
                            district: newValue.id
                          })
                          setSelectedDistrict(newValue)
                        } else
                          setOfficerData({
                            ...officerData,
                            district: null
                          })
                      }
                    }}
                    getOptionLabel={option => (option ? option.name : '')}
                    getOptionSelected={(option, value) => {
                      if (value === '') {
                        return true
                      } else if (value === option) {
                        return true
                      }
                    }}
                  />
                </Grid>
                {loginUserSuper ? (
                  <Grid style={{ padding: '.7%' }} container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormLabel className="wrapLabel" style={{ marginLeft: '8px' }}>
                        Assign Roles
                      </FormLabel>
                      <div className="wrapeRoles">
                        <div className="toggelButtonWrape">
                          <h5>Supervisor</h5>
                          <Switch
                            checked={officerData.is_supervisor}
                            onChange={onRequiredSwitchChange}
                            name="Supervisor"
                            value="supervisor"
                          />
                        </div>
                        <div className="toggelButtonWrape">
                          <h5>Community</h5>
                          <Switch
                            checked={officerData.is_community}
                            onChange={onRequiredSwitchChange}
                            name="Community"
                            value="community"
                          />
                        </div>
                        <div className="toggelButtonWrape">
                          <h5>Employee</h5>
                          <Switch
                            checked={officerData.is_employee}
                            onChange={onRequiredSwitchChange}
                            name="Employee"
                            value="employee"
                          />
                        </div>
                      </div>
                    </Grid>
                    {officerData.showRoles ? (
                      <Grid item xs={12} sm={6}>
                        <OfficerAutocomplete
                          id="combo-box-update-district"
                          options={notificationDist}
                          idTextField="District"
                          label="Notification Assignment"
                          placeholder="Enter Assignment"
                          className="update-officer-autocomplete"
                          value={{
                            ...selectedNotification,
                            name: selectedNotificationName ? selectedNotificationName.name : ''
                          }}
                          onChange={(event, newValue) => {
                            if (newValue !== undefined) {
                              if (newValue) {
                                setSelectedNotification({ id: newValue.id, name: newValue.name })
                                setOfficerData({
                                  ...officerData,
                                  notifications_districts: newValue.id
                                })
                              } else {
                                setSelectedNotification({ id: 'all', name: 'All District' })
                                setOfficerData({
                                  ...officerData,
                                  notifications_districts: 'all'
                                })
                              }
                            }
                          }}
                          getOptionLabel={option => (option ? option.name : '')}
                          getOptionSelected={(option, value) => option.id === value.id}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                ) : null}

                {/* {updateIdOfficer === undefined ? (
                  <Grid container item xs={12} sm={6} direction="column">
                    <Switch
                      onChange={onRequiredSwitchChange}
                      name="checked"
                      color="primary"
                      checked={officerData.is_supervisor}
                      value={officerData.is_supervisor}
                    />
                    <p>Allow Admin Access</p>
                  </Grid>
                ) : (
                  <Grid container item xs={12} sm={6} direction="column">
                    <Switch
                      onChange={onRequiredSwitchChange}
                      name="checked"
                      color="primary"
                      checked={officerData.is_supervisor}
                      value={officerData.is_supervisor}
                    />
                    <p>(Make this Officer Supervisor)</p>
                  </Grid>
                )} */}
              </Grid>
              <div className="d-flex justify-content-end">
                <OfficerButton
                  buttonName="Save"
                  color="officer"
                  variant="small"
                  click={updateOfficer}
                  showSpinnerProp={showSpinner}
                  disabled={
                    !officerData.first_name ||
                    !officerData.last_name ||
                    !officerData.badge_number ||
                    !officerData.email ||
                    emailFormatVerification(officerData.email) ||
                    (officerData.zip_code ? officerData.zip_code.length >= 6 : false) ||
                    (officerData.phone ? !allnumeric(officerData.phone) : false) ||
                    !officerData.district
                  }
                />
              </div>
            </Box>
          </Grid>
        </OfficerLoader>
      </div>
    </>
  )
}
export default UpdateOfficer
