import styled from 'styled-components'
export const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 0 !important;
  font-family: 'Poppins', sans-serif !important;
  color: #323c47 !important;
`
export const Select = styled.select`
  box-sizing: border-box;
  border-radius: 12px;
  height: 56px;
  width: 100%;
  padding-left: 15px;
`
