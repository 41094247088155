import styled from 'styled-components'

export const TableBody = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
`

export const PublicScore = styled.p`
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
  max-width: 60px;
  min-width: 60px;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
  color: #fa9924 !important;
`

export const PublicSentiment = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`

export const PublicScoreText = styled.p`
  font-style: normal;
  text-align: center;
  color: #323c47;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
`

export const StarText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #323c47;
  margin-left: 8px !important;
`

export const StarDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
  min-width: 60px;
`
