/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react'
import { getAPIWithoutAuth, postAPIWithoutAuth } from '../../../utils/api'
import { setEmployeeUrlList, getEmployeeUrlList } from '../../../utils/localStorage'
import { getSurveyByLink, addEmployeeSurveyResponse } from '../../../utils/apiUrls'
import { useParams } from 'react-router-dom'
import {
  DataInstruction,
  QuestionDiv,
  ExpireMessage,
  Image,
  Thankyou,
  ShowMessages,
  TotalResponseCounter,
  ThankyouComment,
  QuestionsContainer
} from './EmployeeSurveyByLinkStyle'
import { Box, Grid } from '@material-ui/core'
import Questions from '../../Survey/Questions'
import OfficerButton from '../../Common/OfficerButton'
import logo from '../../../assets/logo_officer.png'
import OfficerLoader from '../../Common/OfficerLoader/OfficerLoader'
import { validateSurvey } from '../../../utils/helpers'
import './style.css'
import moment from 'moment'
import PropTypes from 'prop-types'
import thumbsup from '../../../assets/thumbsup.svg'
import { CheckSvg } from '../../../utils/svgs'
import OfficerHorizontalBar from '../../Common/OfficerHorizontalBar/OfficerHorizontalBar'

const EmployeeSurveyByLink = props => {
  EmployeeSurveyByLink.propTypes = {
    notify: PropTypes.func.isRequired
  }
  const { link } = useParams()
  const [info, setinfo] = useState(null)

  const [rawQuestionResponse, setRawQuestionResponse] = useState({})
  const [questionIds, setQuestionIds] = useState({})
  const [showSpinner, setShowSpinner] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [expiryDateMessage, setExpiryDateMessage] = useState(false)
  const [surveyFetchingSpinner, setSurveyFetchingSpinner] = useState(false)

  const [survey, setSurvey] = useState({
    questions: [{ choices: [] }]
  })
  const [surveyData, setSurveyData] = useState({
    survey: '',
    questions: []
  })

  const handleCheckboxData = (question, choice, text) => {
    const arr = rawQuestionResponse[question.id].choices.filter(item => item !== choice.id)
    if (arr.length === 0) {
      const obj = rawQuestionResponse
      delete obj[question.id]
      setRawQuestionResponse({ ...obj })
    } else
      setRawQuestionResponse({
        ...rawQuestionResponse,
        [question.id]: {
          question: question.id,
          choice: null,
          text: text,
          choices: [...arr],
          file: null
        }
      })
  }

  const handleQuestionData = (question, choice, text, rating, file) => {
    if (question.type === 'Checkbox') {
      let arr = []
      if (rawQuestionResponse[question.id]) arr = rawQuestionResponse[question.id].choices

      setRawQuestionResponse({
        ...rawQuestionResponse,
        [question.id]: {
          question: question.id,
          choice: null,
          text: text,
          choices: [...arr, choice.id],
          file: null
        }
      })
    } else {
      setRawQuestionResponse({
        ...rawQuestionResponse,
        [question.id]: {
          question: question.id,
          choice: choice ? choice.id : null,
          text: text,
          rating: rating,
          file: file
        }
      })
    }

    setQuestionIds({
      ...questionIds,
      [question.id]: {
        status: true
      }
    })
  }

  const fetchSurveyData = async () => {
    setSurveyFetchingSpinner(true)
    const response = await getAPIWithoutAuth(`${getSurveyByLink}${link}`, {
      date: moment().utc().format('MM-DD-YYYY HH:mm a')
    })

    if (!response.isError) {
      setExpiryDateMessage(false)
      setSurveyFetchingSpinner(false)
      setSurvey(response.data.data.data)
      setSurveyData({
        ...surveyData,
        survey: response.data.data.data.id
      })
    } else {
      setSurveyFetchingSpinner(false)
      setExpiryDateMessage(true)
    }
  }
  useEffect(() => {
    fetchSurveyData()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const submitSurvey = async () => {
    const array = []
    for (const key in rawQuestionResponse) {
      array.push(rawQuestionResponse[key])
    }
    const data = {
      survey: surveyData.survey,

      questions: array
    }
    setShowSpinner(true)
    const response = await postAPIWithoutAuth(addEmployeeSurveyResponse, data)
    if (!response.isError) {
      setShowSpinner(false)
      setShowMessage(true)
      await setEmployeeUrlList(link)
    } else {
      setShowSpinner(false)
      props.notify(response.error.message, 'error')
    }
  }
  useEffect(() => {
    getUrls()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const getUrls = async () => {
    const UrlList = await getEmployeeUrlList()
    if (UrlList.some(item => link.includes(item))) {
      setShowMessage(true)
    }
  }

  const submitPoll = async (question, choice, text) => {
    const data = {
      survey: surveyData.survey,
      questions: [{ question: question.id, choice: choice.id, text: text }]
    }
    setShowSpinner(true)
    const response = await postAPIWithoutAuth(addEmployeeSurveyResponse, data)
    if (!response.isError) {
      setinfo(response.data.data.data.questions[0])
      setShowSpinner(false)
      setShowMessage(true)
      await setEmployeeUrlList(link)
    } else {
      setShowSpinner(false)
      props.notify(response.error.message, 'error')
    }
  }

  const showGraph = () => {
    return (
      <div>
        <OfficerHorizontalBar barItems={info} />
      </div>
    )
  }

  const [steper, setSteper] = useState(0)
  const nextQuestion = (question, count) => {
    const rqrArray = []
    for (const key in rawQuestionResponse) {
      rqrArray.push(rawQuestionResponse[key])
    }
    const steperLength = Object.keys(rawQuestionResponse).length
    if (steperLength === count + 1) {
      if (question.type === 'Text Area') {
        for (let i = 0; i < rqrArray.length; i++) {
          const element = rqrArray[i]
          if (element.question === question.id && element.text.length === 0) {
            props.notify('Your response required!', 'warning', 1000)
            return
          }
        }
      }
      setSteper(steperLength)
    } else {
      if (question.type === 'Text Area') {
        props.notify('Please input your comment', 'warning', 1000)
      } else {
        props.notify('Please choose one of the choices', 'warning', 1000)
      }
    }
  }

  return (
    <>
      {expiryDateMessage ? (
        <>
          <ExpireMessage className=" d-flex justify-content-center  align-items-center">
            Provided suvery is expired now
          </ExpireMessage>
          <div className="d-flex  flex-column justify-content-center align-items-center">
            <p>Powered By</p>
            <img src={logo} alt="officerLogo" width="250x" height="50px" className="logoStyle" />
          </div>
        </>
      ) : showMessage ? (
        <div className="py-3 px-4">
          <Box m={3} className="d-flex  align-items-center flex-column">
            <div className="dataStyle">{survey.title}</div>
            <DataInstruction>{survey.instruction}</DataInstruction>
          </Box>
          <div className="d-flex  flex-column justify-content-center align-items-center" style={{ margin: '10% auto' }}>
            <ShowMessages>
              {survey.type !== 'Poll' ? <Image src={thumbsup} width={'124'} height={'124'} /> : null}
              {survey.type !== 'Poll' ? (
                <div className="thankyouText">Thank you for your time and your feedback!</div>
              ) : (
                <>
                  <Thankyou className="py-4">THANK YOU!</Thankyou>
                  <ThankyouComment>Your feedback has been received.</ThankyouComment>
                  <ThankyouComment>You can now close this window safely.</ThankyouComment>
                </>
              )}
              {survey.type !== 'Poll' ? (
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mt-5">
                  <p>Powered By</p>
                  <img src={logo} alt="officerLogo" width="250x" height="100px" className="logoStyle" />
                </div>
              ) : (
                ''
              )}

              {info ? (
                <div className="d-flex justify-content-center mt-3 align-items-center">
                  <TotalResponseCounter>Total Responses: {info.count}</TotalResponseCounter>
                </div>
              ) : null}
              {info ? <div className="w-100">{showGraph()}</div> : null}
              {survey.type === 'Poll' ? (
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mt-5">
                  <p>Powered By</p>
                  <img src={logo} alt="officerLogo" width="250x" height="100px" className="logoStyle" />
                </div>
              ) : (
                ''
              )}
            </ShowMessages>
          </div>
        </div>
      ) : (
        <div className="py-3 px-4">
          <OfficerLoader key={'hello'} isFetching={surveyFetchingSpinner}>
            <Box m={3} className="d-flex  align-items-center flex-column">
              <div className="dataStyle">{survey.title}</div>
              <DataInstruction>{survey.instruction}</DataInstruction>
            </Box>
            <QuestionsContainer className="questions-container">
              {survey.questions.map((item, count) => (
                <QuestionDiv key={`employee-div${item.id}`}>
                  {count === steper ? (
                    <div>
                      <Grid>
                        <Questions
                          item={item}
                          count={count + 1}
                          handleQuestionData={handleQuestionData}
                          rawQuestionResponse={rawQuestionResponse}
                          submitPoll={submitPoll}
                          handleCheckboxData={handleCheckboxData}
                        />
                      </Grid>
                      {validateSurvey(survey, rawQuestionResponse) ? (
                        <div className="px-sm-0 px-md-4">
                          <Grid item xs={12} sm={12}>
                            <Box mt={3}>
                              <OfficerButton
                                buttonName="Submit"
                                color="officer"
                                variant="small"
                                align="left"
                                disabled={!validateSurvey(survey, rawQuestionResponse)}
                                showSpinnerProp={showSpinner}
                                click={submitSurvey}
                              />
                            </Box>
                          </Grid>
                        </div>
                      ) : (
                        <Box className="px-sm-0 px-md-4 mt-4">
                          <OfficerButton
                            buttonName="Next"
                            color="officer"
                            variant="small"
                            align="left"
                            click={() => nextQuestion(item, count)}
                            endIcon={<CheckSvg color="white" />}
                          />
                        </Box>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </QuestionDiv>
              ))}
              <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                <p>Powered By</p>
                <img src={logo} alt="officerLogo" width="250x" height="100px" className="logoStyle" />
              </div>
            </QuestionsContainer>
          </OfficerLoader>
        </div>
      )}
    </>
  )
}
export default EmployeeSurveyByLink
