import { StatesArray } from './StatesArray'

export { StatesArray }

export const locationNonAuth = [
  '/signupofficer',
  '/department',
  '/employee-survey-by-link',
  '/community-survey-by-link',
  '/officer-survey'
]
