import { SET_EMPLOYEE_SURVEY } from '../actionType'

const initState = {
  employee: {}
}

const employeeReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_EMPLOYEE_SURVEY:
      return {
        ...state,
        employee: action.payload
      }

    default:
      return state
  }
}

export default employeeReducer
