export const loginUrl = 'login/'
export const departmentWithDistrict = 'departmentwithdistrict/'
export const resetPasswordUrl = 'reset-password/'
export const setPassword = 'set-password/'
export const adminLogin = 'admin-login/'
export const refreshTokenUrl = 'refresh/'
export const surveyResponseUrl = 'surveyresponse/'
export const updateSurveyResponseUrl = '/updatesurveyresponse/'
export const districtUrl = 'districts/'
export const surveyResponseOfficerUrl = 'surveyresponse/officer/'
export const officersUrl = 'officer/'
export const officerLogsUrl = 'officerlogs/'
export const smsSurveyListUrl = 'sms-survey/smssurvey'
export const smsSurveyUrl = 'sms-survey/smssurvey/'
export const uploadCsv = 'sms-survey/eventtype/upload/'
export const getEventType = '/sms-survey/eventtype'
export const addEventType = '/sms-survey/eventtype/'
export const callBackUrl = 'call-back/'
export const callBackCountUrl = 'call-back-count/'
export const officersListUrl = 'officers/'
export const officersDepartmentUrl = 'department/'
export const uploadOfficerUrl = 'upload-officer/'
export const supportUrl = 'support/'
export const surveyUrl = 'survey/'
export const deleteQuestionUrl = '/survey/questions/'
export const questionUrl = 'survey/questions/'
export const updateSurveyResponseByofficer = 'updatesurveyresponsebyofficer/'
export const attensionListUrl = 'needtraining/'
export const reviewListUrl = 'intraining/'
export const responseByZip = '/surveyresponsebyzipcode'
export const logoutUrl = 'logout/'
export const searchOfficerUrl = 'searchofficers/'
export const addSurveyUrl = 'addsurvey/'
export const adminSurveyByDepartment = 'survey-responses-by-department/'
export const adminDistrictByDepartment = 'district-by-department/'
export const adminDepartments = 'all-departments/'
export const officerByDepartment = 'officer-by-department/'
export const signUpUrl = 'signup/'
export const notesUrl = '/notes'
export const updateNotesUrl = '/edit-notes/'
export const officerNotesUrl = '/get-notes'
export const addOfficerNotesUrl = '/notes-officer'
export const editDepartmentUrl = '/edit-department/'
export const meApi = '/me/'
export const postSurvey = '/employee-survey/'
export const getSurvey = '/employee-survey/'
export const getSurveyByLink = '/employee-survey-by-link/'
export const addEmployeeSurveyResponse = '/employee-survey-response/'
export const deleteSurveyApi = '/employee-survey/'
export const exportSurvey = 'export-employee-survey-response/'
export const empSurveyResponseReport = '/employee-survey-response-report/'
export const updateEmployeeSurvey = '/employee-survey/'
export const getCommunitySurvey = '/community-survey/'
export const postCommunitySurvey = '/community-survey/'
export const getCommunitySurveyByLink = '/community-survey-by-link/'
export const addCommunitySurveyResponse = '/community-survey-response/'
export const deleteCommunitySurveyApi = '/community-survey/'
export const exportCommunitySurvey = 'export-community-survey-response/'
export const communitySurveyResponseReport = '/community-survey-response-report/'
export const updateCommunitySurvey = '/community-survey/'
export const reArrange = '/survey/questions-reorder/'
export const reArrangeEmployeeFeedback = '/employee-survey-response/questions-reorder/'
export const reArrangeCommunityFeedback = '/community-survey-response/questions-reorder/'
export const demographicResponses = '/demo-graphic-response'
export const exportDemography = 'export-demo-graphic-response'

export const demographicResponseOfficer = '/demo-graphic-response-officer'
export const exportDemographyOfficer = 'export-demo-graphic-response-officer'

export const updateClickSubmitCount = 'sms-survey/messages-send/count/'
export const exportCsv = 'officers/csv'
export const analizeSentiments = 'community/survey/analysis'
export const analizeSentimentsFileUpload = 'community/survey/analysis_fileupload'
export const analizeSentimentsMessage = 'community/survey/analysis_message'
export const chatGptTurboUrl = 'https://api.openai.com/v1/chat/completions'
export const promptExample = 'https://officersurvey.com/example-prompts/'
