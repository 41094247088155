import styled from 'styled-components'
export const Data = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.4px;
  text-align: left;
`

export const DataInstruction = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.4px;
  text-align: left;
`

export const QuestionsContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
  gap: 8px;
  margin: 10% auto;
`

export const QuestionDiv = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-top: 10px;
`

export const SuccessMessage = styled.p`
  height: 30vh;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
`
export const ExpireMessage = styled.p`
  height: 70vh;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #c12a31 !important;
`

export const Image = styled.img`
  object-fit: cover;
`

export const Thankyou = styled.p`
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.4px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

export const Tick = styled.img`
  // width: 80%;
`

export const TotalResponseCounter = styled.div`
  font-weight: 600;
  border: 1px solid #e0e0e0;
  padding: 9px 16px;
  border-radius: 4px;
`

export const ThankyouComment = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #8f959c;
`

export const ShowMessages = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 100%;
  border: 1px solid #e0e0e0;
  @media (max-width: 600px) {
    padding: 1rem;
  }
`
