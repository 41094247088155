export const chatGptTxtConvert = async txt => {
  var choicesArr = []
  var result = []
  var arrOfTxt = txt.split(/(?<=<br \/><br \/>)(?=.*\d)/)
  var arr = []
  await arrOfTxt.forEach(item => {
    if (item.includes('?')) {
      arr.push(item)
    }
  })

  for (let i = 0; i < arr.length; i++) {
    const question = arr[i].split('?')[0] + '?'
    const choiceStr = arr[i].split('?')[1]
    try {
      if (choiceStr.includes(': ')) {
        const choices = choiceStr.split(':')[1].split('<br />')
        for (let j = 0; j < choices.length; j++) {
          const el = choices[j]
          if (/[a-zA-Z0-9]/.test(el)) {
            choicesArr.push(el)
          }
        }
        if (choicesArr.length < 2) {
          const warningMsg = 'This survey is not supported!, Please regenerate!'
          return warningMsg
        }
      } else if (choiceStr.includes('<br />- ') || choiceStr.includes('<br />a) ')) {
        const choices = choiceStr.split('<br />')
        for (let j = 0; j < choices.length; j++) {
          const el = choices[j]
          if (/[a-zA-Z0-9]/.test(el)) {
            choicesArr.push(el)
          }
        }
        if (choicesArr.length < 2) {
          const warningMsg = 'This survey is not supported!, Please regenerate!'
          return warningMsg
        }
      }
    } catch ($e) {
      const warningMsg = 'This survey is not supported!, Please regenerate!'
      return warningMsg
    }

    result.push({ question: question, choices: choicesArr })
    choicesArr = []
  }
  return result
}
