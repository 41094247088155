/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { Label, Input, TextArea } from './OfficerFieldStyle'
import PropTypes from 'prop-types'
import './Inputfield.css'
const OfficerInputField = ({
  id,
  label,
  type,
  value,
  name,
  placeholder,
  onChange,
  error,
  required,
  className,
  min,
  max,
  maxLength,
  multiline,
  rows,
  hintTxt,
  hintUrl,
  disabled,
  characterCount
}) => {
  OfficerInputField.defaultProps = {
    id,
    label: '',
    error: false,
    type: '',
    name: '',
    placeholder: '',
    required: false,
    className: '',
    value: '',
    min: '',
    max: '',
    maxLength: 1000,
    multiline: false,
    rows: 2,
    hintTxt: '',
    hintUrl: 'https://platform.openai.com/docs',
    disabled: false,
    characterCount: false
  }

  OfficerInputField.propTypes = {
    id: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    className: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    maxLength: PropTypes.number,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    hintTxt: PropTypes.string,
    hintUrl: PropTypes.string,
    disabled: PropTypes.bool,
    characterCount: PropTypes.bool
  }
  return (
    <div className="w-100">
      {label ? <Label className="py-2 pl-2 labelStyle">{label}</Label> : ''}
      <br />
      {hintTxt ? (
        <p className="pl-2 hintStyle">
          HINT: {hintTxt}{' '}
          <a href={hintUrl} rel="noopener noreferrer" target="_blank">
            click here!
          </a>
        </p>
      ) : (
        ''
      )}

      {!multiline ? (
        <Input
          type={type}
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          error={error}
          required={required}
          className={className}
          min={min}
          max={max}
          disabled={disabled}
        />
      ) : (
        <TextArea
          type={type}
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          error={error}
          required={required}
          className={className}
          maxLength={maxLength}
          rows={rows}
        />
      )}
      {characterCount ? (
        <div className="float-right">
          {value.length} / {maxLength} Words
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
export default OfficerInputField
