/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState } from 'react'
import OfficerButton from '../../Common/OfficerButton/OfficerButton'
import { CircularProgress, Divider, Grid } from '@material-ui/core'
import { CopyText } from './GenerateEmployeeSurveyStyle'
import { postAPIWrapper, callChatGpt } from '../../../utils/api'
import { postSurvey, chatGptTurboUrl, promptExample } from '../../../utils/apiUrls'
import { OfficerCard, OfficerCopyButton, OfficerInputField, OfficerSelect } from '../../Common'
import logo from '../../../assets/logo_officer.png'
import { QRCodeCanvas } from 'qrcode.react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import './GenerateEmployeeSurvey.css'
import parse from 'html-react-parser'
import { LanguagesArray } from '../../../utils/Constants/LanguagesArray'
import { chatGptTxtConvert } from '../../../utils/chatGptTxtConvert'

const GenerateEmployeeSurvey = ({ notify }) => {
  GenerateEmployeeSurvey.propTypes = {
    notify: PropTypes.func.isRequired
  }
  const ID = () => '_' + Math.random().toString(36).substr(2, 9)
  const [submitSpinner, setSubmitSpinner] = useState(false)
  const [surveyURL, setSurveyURL] = useState()
  const [showSurveyURL, setShowSUrveyURL] = useState(false)
  const { category } = useParams()
  const [targetQuestion, setTargetQuestion] = useState('')
  const [targetAudience, setTargetAudience] = useState('')
  const [transLang, setTransLang] = useState('English')
  const [regenQuestion, setRegenQuestion] = useState('')

  const [newQuestionData, setNewQuestionData] = useState({
    question: '',
    id: ID(),
    type: 'Multiple Choice',
    required: true,
    choices: [],
    rating: 0,
    file: null
  })

  const [survey, setSurvey] = useState({
    title: '',
    instruction: '',
    questions: [],
    survery_category: category
  })

  const submitSurvey = async () => {
    var questionsArr = []
    var choicesArr = []

    const convertedData = await chatGptTxtConvert(turboResult)
    try {
      if (typeof convertedData === 'object') {
        for (let i = 0; i < convertedData.length; i++) {
          const element = convertedData[i]
          for (let j = 0; j < element.choices.length; j++) {
            const choice = element.choices[j]
            choicesArr.push({
              id: ID(),
              choice: choice,
              show_comment_box: false,
              comment_box_place_holder: ''
            })
          }

          questionsArr.push({
            question: element.question,
            id: ID(),
            type: 'Multiple Choice',
            required: true,
            choices: choicesArr,
            rating: 0,
            file: null
          })
          choicesArr = []
        }

        var surveyData = {
          title: survey.title,
          survery_category: survey.survery_category,
          instruction: survey.instruction,
          questions: questionsArr
        }
        setSubmitSpinner(true)
        const res = await postAPIWrapper(postSurvey, surveyData)
        if (!res.isError) {
          setSubmitSpinner(false)
          notify(res.data.data.message, 'success')
          setSurveyURL(res.data.data.data.link)
          setShowSUrveyURL(true)
        } else {
          setSubmitSpinner(false)
          notify(res.error.message, 'error')
        }
      } else if (typeof convertedData === 'string') {
        notify(convertedData, 'error', 1000)
        setRegenQuestion(
          '\n(The previous one was not in bullet format. Or there was something missing in the answer. It also does not require a starting or ending description of the results (just the main ones).. Please give me the correct bullet format with enough items)'
        )
      }
    } catch (e) {
      notify(e, 'error', 1000)
    }
  }

  const downloadQR = () => {
    const canvas = document.getElementById('123456')
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    const downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = 'qr.png'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const [turboResult, setTurboResult] = useState('')
  const [chatSpinner, setChatSpinner] = useState(false)
  const generateChatGPT = async () => {
    if (targetQuestion.length > 0) {
      setChatSpinner(true)
      const language = `. As translated to ${transLang}.`
      var keywords = targetQuestion.concat(
        ' ',
        targetAudience,
        language,
        regenQuestion,
        'Please share question and answer options only no extra text'
      )
      const res = await callChatGpt(chatGptTurboUrl, keywords)
      if (!res.isError) {
        setTurboResult(res.data.data.choices[0].message.content.trim().toString().replace(/\n/g, '<br />'))
        setChatSpinner(false)
      } else {
        setTurboResult(res.error.error.message)
        setChatSpinner(false)
      }
    } else {
      notify('Please input keywords!', 'warning')
    }
  }

  const regenerateChatGpt = async () => {
    setRegenQuestion(
      '\n(The previous one was not in bullet format. Or there was something missing in the answer. It also does not require a starting or ending description of the results (just the main ones).. Please give me the correct bullet format with enough items)'
    )
    generateChatGPT()
  }

  return (
    <>
      {showSurveyURL ? (
        <div className="h-100 ">
          <Grid container direction="row" justify="space-around" spacing={5}>
            <Grid item xs={12} sm={12} md={8} spacing={3}>
              <OfficerCard>
                <div className="d-flex bg-light justify-content-center align-items-center  p-3 ">
                  <div className="pt-2 pr-5">
                    {process.env.REACT_APP_DEPLOYED_LINK}
                    /employee-survey-by-link/
                    {surveyURL}
                  </div>
                  <div className="pl-5">
                    <OfficerCopyButton
                      text={`${process.env.REACT_APP_DEPLOYED_LINK}/employee-survey-by-link/${surveyURL}`}
                      notify={notify}
                      color
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <div className="p-4">
                    <QRCodeCanvas
                      value={`${process.env.REACT_APP_DEPLOYED_LINK}/employee-survey-by-link/${surveyURL}`}
                      id="123456"
                    />
                  </div>
                  <div>
                    <OfficerButton
                      buttonName="Download QR"
                      color="secondary"
                      align="right"
                      variant="medium"
                      click={downloadQR}
                    />
                  </div>
                </div>
                <CopyText className="pt-5 pb-3">
                  Copy and paste the generated link with your community anywhere, in an email, text it, or share it by
                  other means.
                </CopyText>
              </OfficerCard>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className="d-flex flex-wrap flex-lg-nowrap justify-content-around">
          <div className="generate-survey">
            <p className="generate-survey-title">Generate Survey</p>
            <div className="survey-title">
              <OfficerInputField
                placeholder="Enter Survey Title"
                id="title"
                name="title"
                value={survey.title}
                className="title-field"
                onChange={event => setSurvey({ ...survey, title: event.target.value })}
                label="Enter Survey Title (Required)"
              />
            </div>
            <div className="survey-instruction">
              <OfficerInputField
                placeholder="Enter Survey Instructions"
                id="instructions"
                name="instruction"
                maxLength={1000}
                rows={3}
                multiline
                characterCount
                value={survey.instruction}
                className="instruction-field"
                onChange={event => setSurvey({ ...survey, instruction: event.target.value })}
                label="Enter Survey Instructions (Required)"
              />
            </div>
            <div className="survey-type">
              <OfficerInputField
                placeholder="E.g., write me ten survey questions and answers in a bullet format on employee recruitment"
                id="instructions"
                name="instruction"
                maxLength={1000}
                multiline
                rows={3}
                hintTxt={
                  'Your prompt will define your results and be as concise as possible to get the best output. For more prompt hints'
                }
                hintUrl={promptExample}
                characterCount
                value={targetQuestion}
                className="type-field"
                onChange={event => {
                  setTargetQuestion(event.target.value)
                  setNewQuestionData({
                    ...newQuestionData,
                    question: targetQuestion
                  })
                }}
                label="What kind of survey can I create for you?"
              />
            </div>
            <div className="translate-lang">
              <OfficerSelect
                id="translateLang"
                placeholder="Select Language"
                value={transLang}
                langArray={LanguagesArray}
                className="audience-field"
                onChange={event => setTransLang(event.target.value)}
                label="Translate to"
              />
            </div>
            <div className="target-audience">
              <OfficerInputField
                placeholder="E.g, Employees / Community Members"
                id="targetAudience"
                name="targetAudience"
                value={targetAudience}
                className="audience-field"
                onChange={event => setTargetAudience(event.target.value)}
                label="Target Audience (Optional)"
              />
            </div>
            <div className="survey-divider">
              <Divider style={{ backgroundColor: '#BFC0C9' }} />
            </div>

            <div className="mb-4">
              <OfficerButton
                buttonName="Generate"
                color="officer"
                align="right"
                variant="extraLarge"
                click={generateChatGPT}
              />
            </div>
          </div>
          <div className="generate-results">
            <div className="results-title">Results</div>
            <div className="result-box">
              <div>{chatSpinner ? <CircularProgress /> : ''}</div>
              <div>{parse(turboResult)}</div>
            </div>
            <div className="pt-4 d-flex flex-wrap justify-content-between">
              <OfficerButton
                buttonName="Regenerate"
                color="officer"
                align="right"
                variant="extraLarge"
                disabled={turboResult.length === 0 ? true : false}
                click={regenerateChatGpt}
              />
              <OfficerButton
                buttonName="Create Survey"
                color="officer"
                align="right"
                variant="extraLarge"
                disabled={turboResult.length === 0 ? true : false}
                showSpinnerProp={submitSpinner}
                click={submitSurvey}
              />
            </div>
          </div>
        </div>
      )}

      <div className="d-flex  flex-column justify-content-center align-items-center">
        <p className="mt-4">Powered By</p>
        <img src={logo} alt="officerLogo" width="250x" height="100px" className="logoStyle" />
      </div>
    </>
  )
}
export default GenerateEmployeeSurvey
