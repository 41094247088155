import styled from 'styled-components'
export const AssignmntHeading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
`
export const CardTitle = styled.p`
  color: #323c47;
  font-style: normal;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
`

export const UpdateHeading = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #194fbb !important;
  padding-bottom: 15px !important;
`

export const AssignmentCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  width: 100%;
  height: 140px;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
`
